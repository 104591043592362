import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.addsearchKeys,
      getDataWithKey: _vm.getDataWithKey,
      disabledDate: _vm.disabledDate
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("添加")])];
      },
      proxy: true
    }])
  })], 1), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.addcolumns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading
    },
    scopedSlots: _vm._u([_vm._l(_vm.addcolumns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [key == "imgurls" ? _c("span", [record.imgurls ? _c("img", {
            staticClass: "product_img",
            attrs: {
              src: _vm.splitImg(record)
            },
            on: {
              click: function click($event) {
                _vm.preview(_vm.splitImg(record));
              }
            }
          }) : _c("span", [_vm._v(" --- ")])]) : key == "name" ? _c("span", [_c("span", [_vm._v(_vm._s(record.name.length > 30 ? record.name.slice(0, 30) + "..." : record.name))])]) : key == "managementcategory" ? _c("span", [_c("span", [_vm._v(_vm._s(record.parentcategoryname + "-" + record.categoryname))])]) : key == "operation" ? _c("span", [_c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["comgood:add:submit"],
              expression: "['comgood:add:submit']"
            }],
            on: {
              click: function click($event) {
                return _vm.deletegoods(record);
              }
            }
          }, [_vm._v("删除")])]) : _c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "combinbtn"
  }, [_c("a-button", {
    staticClass: "btn_1",
    staticStyle: {
      "margin-right": "26px"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["comgood:add:submit"],
      expression: "['comgood:add:submit']"
    }],
    attrs: {
      disabled: _vm.isbtn,
      type: "primary",
      size: "large"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(" 确定 ")])], 1)], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };